import { handleActions, createAction } from "redux-actions";
import { AxiosWrapper, axios } from "utils/axiosWrapper";
import uniqid from "uniqid";

const { getMegazoneAuthentication } = window;
const apiEndpoint = "https://z8qv6i1rg6.execute-api.ap-northeast-2.amazonaws.com/dev/api";
//action type
const ME_PENDING = "CHECK_ME_PENDING";
const CHECK_ME_FAILURE = "CHECK_ME_FAILURE";
const CHECK_ME_SUCCESS = "CHECK_ME_SUCCESS";
const RESET_ME = "RESET_ME";
const GET_ME_SUCCESS = "GET_ME_SUCCESS";
const GET_ME_FAILURE = "GET_ME_FAILURE";

//reducer
const initialState = {
    info: null,
    error: false,
    pending: false,
};

export default handleActions(
    {
        [ME_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [CHECK_ME_FAILURE]: (state, action) => {
            const { error } = action.payload;
            resetHeader();
            return {
                ...state,
                pending: false,
                error: {
                    msg: error,
                },
            };
        },
        [CHECK_ME_SUCCESS]: (state, action) => {
            const { tid, user } = action.payload;
            setHeader(tid);
            return {
                pending: false,
                error: false,
                info: {
                    tid,
                    user,
                },
            };
        },
        [RESET_ME]: (state) => {
            resetHeader();
            return {
                pending: false,
                error: false,
                info: null,
            };
        },
        [GET_ME_FAILURE]: (state, action) => {
            const { error } = action.payload;
            return {
                ...state,
                pending: false,
                error: {
                    msg: error.response.data,
                },
            };
        },
        [GET_ME_SUCCESS]: (state, action) => {
            const { result } = action.payload;
            return {
                pending: false,
                error: false,
                info: {
                    ...state.info,
                    user: result,
                },
            };
        },
    },
    initialState,
);

function setHeader(tid) {
    AxiosWrapper.setDefaultHeaders({
        Authorization: tid,
    });
}

function resetHeader() {
    AxiosWrapper.setDefaultHeaders({
        Authorization: "",
    });
}

async function checkInfo() {
    return await getMegazoneAuthentication();
}

export const checkAdmin = () => (dispatch, getState) => {
    dispatch({ type: ME_PENDING });
    return new Promise(async (resolve, reject) => {
        try {
            const response = await checkInfo();
            if (response.tid) {
                const { data } = await getAccountInfoAPI(response.tid);
                response.user = data;
            }
            dispatch({
                type: CHECK_ME_SUCCESS,
                payload: response,
            });

            resolve(response);
        } catch (error) {
            dispatch({
                type: CHECK_ME_FAILURE,
                payload: error,
            });
            reject(error);
        }
    });
};

async function getAccountInfoAPI(tid) {
    return axios.get(`${apiEndpoint}/me`, {
        headers: {
            Authorization: tid,
        },
    });
}

export const resetInfo = createAction(RESET_ME);
