import React, { useState, useEffect } from "react";
import ContactInput from "./components/ContactInput";
import uniqid from "uniqid";

const ContactList = (props) => {
    // @params givenList: Array
    // @params setGivenList: Function
    const { register, errors, setValue, isSubmit, contactList: defaultContactList } = props;
    const limit = 1;
    // hooks
    const [contactList, setContactList] = useState([{ id: uniqid(), phone1: "010", phone2: "", phone3: "" }]);
    // functions
    const createContactList = () => {
        const changedValue = contactList.concat({ id: uniqid(), phone1: "010", phone2: "", phone3: "" });
        setContactList(changedValue);
    };
    const deleteContactList = (id) => {
        const filteredList = contactList.filter((item) => item.id !== id);
        setContactList(filteredList);
    };
    const editContactList = (value) => {
        const editedList = contactList.map((item) => {
            if (item.id === value.id) {
                item = value;
            }
            return item;
        });
        setContactList(editedList);
    };

    useEffect(() => {
        contactList.forEach((item, index) => {
            const makeNumber = {
                is_default: index === 0,
                phone_number: `${item.phone1}-${item.phone2}-${item.phone3}`,
            };
            setValue(`contactList[${index}]`, makeNumber, { shouldValidate: isSubmit });
        });
    }, [contactList]);

    useEffect(() => {
        if (defaultContactList) {
            setContactList(
                defaultContactList.map((item) => {
                    const splitNumber = item.phone_number.split("-");
                    return {
                        id: uniqid(),
                        phone1: splitNumber[0] || "010",
                        phone2: splitNumber[1] || "",
                        phone3: splitNumber[2] || "",
                    };
                }),
            );
        }
    }, [defaultContactList]);

    return (
        <React.Fragment>
            {contactList.map((item, i) => {
                return (
                    <div
                        className={`form-input ${errors.contactList && errors.contactList[i] ? " form-error" : ""}`}
                        key={item.id}
                    >
                        <div className={`row row-column`} style={{ paddingRight: errors.contactList ? "30px" : "0" }}>
                            <ContactInput
                                listLength={contactList.length}
                                index={i}
                                limit={limit}
                                value={item}
                                editContactList={editContactList}
                                createContactList={createContactList}
                                deleteContactList={deleteContactList}
                            />
                            <input
                                type="hidden"
                                name={`contactList[${i}].phone_number`}
                                ref={register({
                                    validate: {
                                        check1: (value) => (value && value.length > 6) || `연락처를 입력해주세요.`,
                                        check2: (value) =>
                                            (value && /^01([0|1|6|7|8|9])-?([\d]{3,4})-?([\d]{4})$/.test(value)) ||
                                            "연락처 양식이 아닙니다.",
                                    }
                                })}
                            />
                            <input type="hidden" name={`contactList[${i}].is_default`} ref={register} />
                        </div>
                        {errors.contactList && errors.contactList[i] ? (
                            <div className="form-message">
                                <p>{errors.contactList[i].phone_number.message}</p>
                            </div>
                        ) : null}
                    </div>
                );
            })}
        </React.Fragment>
    );
};

export default ContactList;
