import React, { useReducer, useEffect } from "react";
import WithSelect from "components/WithSelect";

const ContactInput = (props) => {
    // @params index : Number
    // @params values : Object => { id: String, phone1: String, phone2: String, phone3: String }
    // @params editContactList : Function
    // @params createContactList : Function
    // @params deleteContactList : Function
    const { index, value, editContactList, createContactList, deleteContactList, listLength, limit } = props;

    // hooks
    const [inputValues, setInputValues] = useReducer((state, newState) => ({ ...state, ...newState }), value);

    //functions
    const handleOnChange = (event) => {
        const { name, value } = event.target;
        setInputValues({ [name]: value });
    };

    const handleOnSelect = (event) => {
        const { value } = event;
        setInputValues({ phone1: value });
    };

    // component life cycle
    useEffect(() => {
        editContactList(inputValues);
    }, [inputValues]);

    // button Components
    const CreateListBtn = (props) => {
        return (
            <button
                type="button"
                className="btn btn-icon-solid"
                onClick={createContactList}
                disabled={listLength > limit ? true : false}
            >
                <i className="sprite sprite-add"></i>
            </button>
        );
    };

    const DeleteListBtn = (props) => {
        // @params id : String
        const { id } = props;
        return (
            <button type="button" className="btn btn-icon-solid" onClick={() => deleteContactList(id)}>
                <i className="sprite sprite-cancel-fade"></i>
            </button>
        );
    };

    // values for rendering
    const { id, phone1, phone2, phone3 } = inputValues;
    const selectOptions = [
        { label: "010", value: "010" },
        { label: "011", value: "011" },
        { label: "016", value: "016" },
        { label: "017", value: "017" },
        { label: "018", value: "018" },
        { label: "019", value: "019" },
    ];
    return (
        <React.Fragment>
            <div className="col">
                <WithSelect
                    options={selectOptions}
                    hideSelectedOptions={false}
                    onChange={handleOnSelect}
                    name="phone1"
                    defaultValue={phone1}
                    className="contact-phone1"
                    noOptionsMessage={() => "No Options"}
                />
            </div>
            <div className="col">
                <div className="form-input">
                    <input type="tel" name="phone2" maxLength={4} value={phone2} onChange={handleOnChange} />
                </div>
            </div>
            <div className="col">
                <div className="form-input">
                    <input type="tel" name="phone3" maxLength={4} value={phone3} onChange={handleOnChange} />
                </div>
            </div>
            <div className="col separate">{index === 0 ? <CreateListBtn /> : <DeleteListBtn id={id} />}</div>
        </React.Fragment>
    );
};

export default ContactInput;
