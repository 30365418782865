import "core-js/stable";
import "regenerator-runtime/runtime";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import Root from "./Root";
import reducers from "./reducers";
import reportWebVitals from "./react.utils/reportWebVitals";
import { unregister } from "./react.utils/registerServiceWorker";

const middleware =
    process.env.REACT_APP_NOT_SECRET_CODE &&
    (process.env.REACT_APP_NOT_SECRET_CODE.trim() === "development" ||
        process.env.REACT_APP_NOT_SECRET_CODE.trim() === "local")
        ? composeWithDevTools(applyMiddleware(thunk))
        : applyMiddleware(thunk);

const store = createStore(reducers, middleware);

async function run() {
    ReactDOM.render(<Root store={store} />, document.getElementById("root"));
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
    unregister();
}

const loadedStates = ["complete", "loaded", "interactive"];

if (loadedStates.indexOf(document.readyState) !== -1 && document.body) {
    run();
} else {
    window.addEventListener("DOMContentLoaded", run, false);
}
