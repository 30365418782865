import { handleActions, createAction } from "redux-actions";
import { axios } from "utils/axiosWrapper";

const apiEndpoint = "https://z8qv6i1rg6.execute-api.ap-northeast-2.amazonaws.com/dev/api";
//action type
const GET_USERS_PENDING = "GET_USERS_PENDING";
const GET_USERS_FAILURE = "GET_USERS_FAILURE";
const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
const RESET_USERS = "RESET_USERS";
//reducer
const initialState = {
    pending: false,
    error: false,
    data: null,
};

export default handleActions(
    {
        [GET_USERS_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [GET_USERS_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_USERS_SUCCESS]: (state, action) => {
            let { data } = action.payload;
            return {
                pending: false,
                error: false,
                data: data.accounts,
            };
        },
        [RESET_USERS]: (state) => {
            return {
                pending: false,
                error: false,
                data: null,
            };
        },
    },
    initialState,
);

function getUserListApi() {
    return axios.get(`${apiEndpoint}/accounts`);
}

export const getUserList = () => (dispatch, getState) => {
    dispatch({ type: GET_USERS_PENDING });
    return new Promise((resolve, reject) => {
        getUserListApi()
            .then((response) => {
                dispatch({
                    type: GET_USERS_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: GET_USERS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};


function getUserListSyncApi() {
    return axios.get(`${apiEndpoint}/sync-accounts`);
}

export const getUserListSync = () => (dispatch, getState) => {
    dispatch({ type: GET_USERS_PENDING });
    return new Promise((resolve, reject) => {
        getUserListSyncApi()
            .then((response) => {
                dispatch({
                    type: GET_USERS_SUCCESS,
                    payload: response,
                });
                resolve(response);
            })
            .catch((error) => {
                dispatch({
                    type: GET_USERS_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const resetUserList = createAction(RESET_USERS);
