import React from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Toast from "components/Toast";

const Layout = ({ children }) => {
    return (
        <div className="wrap" id="wrap">
            <Header />
            <section className="container" id="container">
                <main className="main">
                    {children}
                    <Footer />
                </main>
            </section>
            <Toast />
        </div>
    );
};

export default Layout;
