import React, { useReducer, useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import ContactList from "components/ContactList";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "reducers/modules/user";
import Loader from "components/Loader";

const CreateAccount = (props) => {
    const { history } = props;
    // hooks
    const { register, errors, handleSubmit, watch, setValue } = useForm({
        defaultValues: {
            userEmail: "",
            userName: "",
            name: "",
            description: "",
            adminId: "",
            noticeEmail: "",
            recommendedId: "",
            salesPlanningId: "",
            billEmail: "",
            toggleNoticeEmail: true,
            toggleBillEmail: true,
        },
    });
    const [isSubmit, setIsSubmit] = useState(false);
    const { user } = useSelector((store) => store);
    const { userEmail, password, toggleNoticeEmail, toggleBillEmail, noticeEmail, contactList } = watch();
    const dispatch = useDispatch();
    const UserActions = bindActionCreators(userActions, dispatch);

    const handleOnClick = () => {
        setIsSubmit(true);
    };
    const onSubmit = async (data) => {
        const { userEmail, userName, description, name, contactList, noticeEmail, password, adminId, billEmail } = data;
        const queryParams = {
            name,
            description,
            reception_email: adminId, // 청구 위임 받을 계정
            user_email: userEmail, // 사용자 email id
            user_name: userName, // 사용자 name
            phone_numbers: contactList.map((item, index) => {
                item.idx = index;
                return item;
            }), // 연락처
            reception_email: toggleNoticeEmail ? userEmail : noticeEmail, // 공지수신 email
            password: password, // 비밀번호
            billing_email: toggleBillEmail ? userEmail : billEmail, // 청구받을 email주소
        };
        try {
            await UserActions.createUserInfo(queryParams);
            history.push("/account/list");
        } catch (error) {
            console.log("error: ", error);
        }
    };
    const handleCancel = () => {
        history.push("/account/list");
    };
    useEffect(() => {
        if (toggleNoticeEmail) {
            setValue("noticeEmail", userEmail);
        }
    }, [userEmail]);
    useEffect(() => {
        if (!toggleNoticeEmail) {
            setValue("noticeEmail", "");
        } else {
            setValue("noticeEmail", userEmail);
        }
    }, [toggleNoticeEmail]);

    useEffect(() => {
        if (toggleBillEmail) {
            setValue("billEmail", userEmail);
        }
    }, [userEmail]);
    useEffect(() => {
        if (!toggleBillEmail) {
            setValue("billEmail", "");
        } else {
            setValue("billEmail", userEmail);
        }
    }, [toggleBillEmail]);
    return (
        <div className="contents">
            {user.pending && <Loader />}
            <div className="page-header">
                <div className="page-header-info">
                    <h2 className="page-title">계정 생성</h2>
                </div>
            </div>
            <div className="card card-form card-account">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col col-3">
                                <strong>계정 이름</strong>
                                <i className="required">*</i>
                            </div>
                            <div className="col col-9">
                                <div className={`form-input ${errors.name ? "form-error" : ""}`}>
                                    <input
                                        type={"text"}
                                        id={"name"}
                                        name={"name"}
                                        placeholder="계정 이름"
                                        ref={register({
                                            required: "계정 이름을 입력해주세요.",
                                            validate: {
                                                check1: (value) => value.length < 100 || "100자를 초과할 수 없습니다.",
                                            },
                                        })}
                                    />
                                    {errors.name ? (
                                        <div className="form-message">
                                            <p>{errors.name.message}</p>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-3">
                                <strong>설명 </strong>
                            </div>
                            <div className="col col-9">
                                <div className={`form-textarea ${errors.description ? "form-error" : ""}`}>
                                    <textarea
                                        id={"description"}
                                        name={"description"}
                                        placeholder="설명"
                                        ref={register({
                                            validate: {
                                                check1: (value) =>
                                                    value.length < 1000 || "1000자를 초과할 수 없습니다.",
                                            },
                                        })}
                                    ></textarea>
                                    {errors.description ? (
                                        <div className="form-message">
                                            <p>{errors.description.message}</p>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col col-3">
                                <strong>계정 이메일</strong>
                                <i className="required">*</i>
                            </div>
                            <div className="col col-9">
                                <div className={`form-input ${errors.userEmail ? "form-error" : ""}`}>
                                    <input
                                        type={"text"}
                                        id={"userEmail"}
                                        name={"userEmail"}
                                        placeholder="이메일 주소 입력"
                                        ref={register({
                                            required: "이메일을 입력해주세요.",
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "이메일 형식이 아닙니다.",
                                            },
                                        })}
                                    />
                                    {errors.userEmail ? (
                                        <div className="form-message">
                                            <p>{errors.userEmail.message}</p>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-3">
                                <strong>사용자</strong>
                                <i className="required">*</i>
                            </div>
                            <div className="col col-9">
                                <div className={`form-input ${errors.userName ? "form-error" : ""}`}>
                                    <input
                                        type={"text"}
                                        id={"userName"}
                                        name={"userName"}
                                        placeholder="사용자 입력"
                                        ref={register({
                                            required: "사용자를 입력해주세요.",
                                            validate: {
                                                check1: (value) =>
                                                    /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\*]+$/.test(value) ||
                                                    "한글, 영문, 숫자만 가능합니다.",
                                                check2: (value) => value.length < 30 || "30자를 초과할 수 없습니다.",
                                            },
                                            // pattern: {
                                            //     value: /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\*]+$/,
                                            //     message: "한글, 영문, 숫자만 가능합니다.",
                                            // },
                                        })}
                                    />
                                    {errors.userName ? (
                                        <div className="form-message">
                                            <p>{errors.userName.message}</p>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-3">
                                <strong>비밀번호</strong>
                                <i className="required">*</i>
                            </div>
                            <div className="col col-9">
                                <div className="row">
                                    <div className="col-6">
                                        <div className={`form-input ${errors.password ? "form-error" : ""}`}>
                                            <input
                                                type={"password"}
                                                id={"password"}
                                                name={"password"}
                                                placeholder="영문 대/소문자, 숫자, 특수문자 조합 8~15자"
                                                ref={register({
                                                    required: "비밀번호를 입력해주세요.",
                                                    pattern: {
                                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,15}$/i,
                                                        message:
                                                            "영문 대/소문자, 숫자, 특수문자 포함한 8~15자로 조합해주세요.",
                                                    },
                                                })}
                                            />
                                            {errors.password ? (
                                                <div className="form-message">
                                                    <p>{errors.password.message}</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-3">
                                <strong>비밀번호 확인</strong>
                                <i className="required">*</i>
                            </div>
                            <div className="col col-9">
                                <div className="row">
                                    <div className="col-6">
                                        <div className={`form-input ${errors.confirmPassword ? "form-error" : ""}`}>
                                            <input
                                                type={"password"}
                                                id={"confirmPassword"}
                                                name={"confirmPassword"}
                                                placeholder="비밀번호 재입력"
                                                ref={register({
                                                    required: "비밀번호를 한번 더 입력해주세요.",
                                                    validate: (value) => {
                                                        return value === password || "비밀번호가 일치하지 않습니다.";
                                                    },
                                                })}
                                            />
                                            {errors.confirmPassword ? (
                                                <div className="form-message">
                                                    <p>{errors.confirmPassword.message}</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-3">
                                <strong>연락처</strong>
                                <i className="required">*</i>
                            </div>
                            <div className="col col-9">
                                <div className="row">
                                    <div className="col-7">
                                        {
                                            <ContactList
                                                register={register}
                                                errors={errors}
                                                setValue={setValue}
                                                isSubmit={isSubmit}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col col-3">
                                <strong>공지 이메일</strong>
                            </div>
                            <div className="col col-9">
                                <div className="row row-column">
                                    <div className="col flex-grow-1">
                                        <div
                                            className={`form-input ${
                                                errors.noticeEmail && !toggleNoticeEmail ? "form-error" : ""
                                            }`}
                                        >
                                            <input
                                                type={"text"}
                                                id={"noticeEmail"}
                                                name={"noticeEmail"}
                                                placeholder="공지 수신용 이메일 입력"
                                                disabled={toggleNoticeEmail}
                                                ref={register({
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "이메일 형식이 아닙니다.",
                                                    },
                                                })}
                                            />
                                            {errors.noticeEmail && !toggleNoticeEmail ? (
                                                <div className="form-message">
                                                    <p>{errors.noticeEmail.message}</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col col-4">
                                        <label htmlFor="toggleNoticeEmail" className="form-checkbox">
                                            <input
                                                type={"checkbox"}
                                                id={"toggleNoticeEmail"}
                                                name={"toggleNoticeEmail"}
                                                ref={register}
                                            />
                                            <i></i>
                                            <span className="text-secondary">계정 이메일과 동일</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col col-3">
                                <strong>이메일로 청구 받기</strong>
                            </div>
                            <div className="col col-9">
                                <div className="row row-column">
                                    <div className="col flex-grow-1">
                                        <div
                                            className={`form-input ${
                                                errors.billEmail && !toggleBillEmail ? "form-error" : ""
                                            }`}
                                        >
                                            <input
                                                type={"text"}
                                                id={"billEmail"}
                                                name={"billEmail"}
                                                placeholder="청구 받을 이메일 주소 입력"
                                                disabled={toggleBillEmail}
                                                ref={register({
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                        message: "이메일 형식이 아닙니다.",
                                                    },
                                                })}
                                            />
                                            {errors.billEmail && !toggleBillEmail ? (
                                                <div className="form-message">
                                                    <p>{errors.billEmail.message}</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col col-4">
                                        <label htmlFor="toggleBillEmail" className="form-checkbox">
                                            <input
                                                type={"checkbox"}
                                                id={"toggleBillEmail"}
                                                name={"toggleBillEmail"}
                                                ref={register}
                                            />
                                            <i></i>
                                            <span className="text-secondary">계정 이메일과 동일</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div>
                            <button type="reset" className="btn btn-outline-default" onClick={handleCancel}>
                                <span>취소</span>
                            </button>
                            <button type="submit" className="btn btn-primary" onClick={handleOnClick}>
                                <span>생성</span>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default withRouter(CreateAccount);
