import { combineReducers } from "redux";
import users from "./modules/users";
import user from "./modules/user";
import authentication from "./modules/authentication";
import toastMessage from "./modules/toastMessage";

const appReducers = combineReducers({
    users,
    user,
    authentication,
    toastMessage,
});

const reducers = (state, action) => {
    if (action.type === "GLOBAL_RESET_ALL") {
        state = undefined;
    }
    return appReducers(state, action);
};

export default reducers;
