import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Header = (props) => {
    const dispatch = useDispatch();
    const { authentication } = useSelector((store) => store);

    const onClickLogout = () => {
        window.location = "https://login.megazone.com/logout?app=admin";
    };
    return (
        <header className="header">
            <h1>
                <Link to="/">
                    <img src="/images/KT_Logo.png" alt=""/>
                </Link>
            </h1>
            <div className="utils">
                <div className="utils-menu utils-profile">
                    <a href={"https://start.megazone.com/profile/info"} target={"_blank"}>
                        <span className="thumbnail">
                            {authentication && authentication.info && authentication.info.user && authentication.info.user.profileImageUrl ? (
                                <img src={authentication.info.user.profileImageUrl} />
                            ) : (
                                <i className={"sprite sprite-user"}></i>
                            )}
                        </span>
                        <div>
                            <strong>
                                {authentication && authentication.info && authentication.info.user
                                    ? authentication.info.user.name
                                    : ""}
                            </strong>
                            <small>
                                {authentication && authentication.info && authentication.info.user
                                    ? authentication.info.user.email
                                    : ""}
                            </small>
                        </div>
                    </a>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            onClickLogout();
                        }}
                        type={"button"}
                        className={"btn-icon-solid btn-logout "}
                    >
                        <i className={"sprite sprite-logout "}></i>
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;
