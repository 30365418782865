import Axios from "axios";
// import TokenKeeper from "./tokenKeeper";

export class AxiosWrapper {
    static axiosWithHeaders = null;
    static axiosWithoutHeaders = null;

    /**
     * @returns {CommonDataManager}
     */
    static getInstance(withHeaders) {
        if (withHeaders) {
            if (AxiosWrapper.axiosWithHeaders == null) {
                AxiosWrapper.axiosWithHeaders = new AxiosWithHeaders();
            }
            return this.axiosWithHeaders;
        } else {
            if (AxiosWrapper.axiosWithoutHeaders == null) {
                AxiosWrapper.axiosWithoutHeaders = new AxiosWithoutHeaders();
            }
            return this.axiosWithoutHeaders;
        }
    }

    static setDefaultHeaders(headers) {
        this.getInstance(true).setDefaultHeaders(headers);
    }
}

class AxiosAbstract {
    setDefaultHeaders(headers) {
        throw new Error("Not Implemented!");
    }

    post(url, data, config) {
        throw new Error("Not Implemented!");
    }
    put(url, data, config) {
        throw new Error("Not Implemented!");
    }
    patch(url, data, config) {
        throw new Error("Not Implemented!");
    }
    delete(url, config) {
        throw new Error("Not Implemented!");
    }
    get(url, config) {
        throw new Error("Not Implemented!");
    }
    head(url, config) {
        throw new Error("Not Implemented!");
    }
    isCancel(err) {
        throw new Error("Not Implemented!");
    }
}

class AxiosWithHeaders extends AxiosAbstract {
    _defaultHeaders = {};
    _interceptors = Axios.interceptors;
    _cancelToken = Axios.CancelToken;

    setDefaultHeaders(headers) {
        // const token = TokenKeeper.getInstance().get();
        // headers["Authorization"] = token;
        this._defaultHeaders = {
            ...headers,
        };
    }
    post(url, data, config) {
        return Axios.post(url, data, this._merge(config));
    }
    put(url, data, config) {
        return Axios.put(url, data, this._merge(config));
    }
    patch(url, data, config) {
        return Axios.patch(url, data, this._merge(config));
    }
    get(url, config) {
        return Axios.get(url, this._merge(config));
    }
    delete(url, config) {
        return Axios.delete(url, this._merge(config));
    }
    head(url, config) {
        return Axios.head(url, this._merge(config));
    }
    isCancel(err) {
        return Axios.isCancel(err);
    }

    _merge(config) {
        const configParams = config || {};
        return {
            ...configParams,
            headers: {
                ...this._defaultHeaders,
                ...configParams.headers,
            },
        };
    }
}

class AxiosWithoutHeaders extends AxiosAbstract {
    _interceptors = Axios.interceptors;
    _cancelToken = Axios.CancelToken;

    setDefaultHeaders(headers) {
        throw new "Not Supported!"();
    }
    post(url, data, config) {
        return Axios.post(url, data, config);
    }
    put(url, data, config) {
        return Axios.put(url, data, config);
    }
    patch(url, data, config) {
        return Axios.patch(url, data, config);
    }
    get(url, config) {
        return Axios.get(url, config);
    }
    delete(url, config) {
        return Axios.delete(url, config);
    }
    head(url, config) {
        return Axios.head(url, config);
    }
    isCancel(err) {
        return Axios.isCancel(err);
    }
}

export const axios = AxiosWrapper.getInstance(true);
export const axiosWithoutHeaders = AxiosWrapper.getInstance(false);
