import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as toastActions from "reducers/modules/toastMessage";

const Container = (props) => {
    const { message } = props;
    const dispatch = useDispatch();
    const ToastActions = bindActionCreators(toastActions, dispatch);
    useEffect(() => {
        const { error } = toast;
        error(message.contents, {
            onClose: () => {
                ToastActions.deleteToast(message._id);
            },
        });
    }, []);
    return (
        <ToastContainer
            position={"top-right"}
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
        />
    );
};

const Toast = (props) => {
    const { toastMessage } = useSelector((store) => store);
    return (
        <React.Fragment>
            {toastMessage.data.length > 0
                ? toastMessage.data.map((v, i) => (
                      <React.Fragment key={i}>
                          <Container message={v} />
                      </React.Fragment>
                  ))
                : ""}
        </React.Fragment>
    );
};

export default Toast;
