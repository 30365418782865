/**
 * components
 */
import CreateAccount from "./../components/CreateAccount";
import AccountList from "./../components/AccountList";
import AccountDetail from "./../components/AccountDetail";
import Layout from "components/Layout";

export const routes = [
    {
        name: "Create Account",
        exact: true,
        path: "/accounts/add",
        component: CreateAccount,
        layout: Layout,
        redirectPath: "/accounts/add",
    },
    {
        name: "Account List",
        exact: true,
        path: "/accounts",
        component: AccountList,
        layout: Layout,
        redirectPath: "/accounts",
    },
    {
        name: "Account Detail",
        exact: true,
        path: "/accounts/:id",
        component: AccountDetail,
        layout: Layout,
        redirectPath: "/accounts/:id",
    },
];
