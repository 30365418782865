import React from "react";
import { Link } from "react-router-dom";
const Footer = (props) => {
    return (
        <footer className="footer">
            <span>
                Copyright ⓒ 2021. {' '}
                <a href="https://www.megazone.com/" className="text-primary" target='_blank'>
                    MegazoneCloud
                </a>
                . All rights reserved.
            </span>
        </footer>
    );
};

export default Footer;
