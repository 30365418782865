import React, { useReducer, useState, useEffect, useRef, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import moment from "moment";
import ContactList from "components/ContactList";
import * as userActions from "reducers/modules/user";
import Loader from "components/Loader";

const AccountDetail = (props) => {
    const { history, match } = props;
    const { user, authentication } = useSelector((store) => store);
    const dispatch = useDispatch();
    const [userId, setUserId] = useState(match.params.id || "");
    const UserActions = bindActionCreators(userActions, dispatch);
    const [userInfo, setUserInfo] = useState({
        user_email: "-",
        user_name: "-",
        admin_id: "-",
        reception_email: "-",
        seller_id: "-",
        billing_email: "-",
        name: "-",
        description: "-",
        toggle: true,
        phone_numbers: [],
        creator_id: "-",
        creator_name: "-",
        created_at: new Date(),
        updater_id: "-",
        updater_name: "-",
        updated_at: new Date(),
    });

    // hooks
    const { register, errors, handleSubmit, watch, setValue, getValues } = useForm({
        mode: `onChange`,
        reValidateMode: 'onChange',
        defaultValues: {
            userName: "",
            name: "",
            description: "",
            receptionEmail: "",
            toggle: true,
            password: "",
            confirmPassword: "",
        },
    });
    const { toggle, password, receptionEmail, contactList } = watch();
    const isValidationOnChange = true;
    const [editName, setEditName] = useState(false);
    const [editUserName, setEditUserName] = useState(false);
    const [editDescription, setEditDescription] = useState(false);
    const [editNoticeEmail, setEditNoticeEmail] = useState(false);
    const [editContactList, setEditContactList] = useState(false);
    const [editPassword, setEditPassword] = useState(false);

    useEffect(() => {
        if (match.params.id) {
            setUserId(match.params.id);
        }
    }, [match]);

    useEffect(() => {
        if (editName) {
            setValue("name", userInfo.name);
        }
        if (editUserName) {
            setValue("userName", userInfo.user_name);
        }
        if (editDescription) {
            setValue("description", userInfo.description);
        }
        if (editNoticeEmail) {
            setValue("toggle", userInfo.toggle);
            setValue("receptionEmail", userInfo.receptionEmail);
        }
        if (editContactList) {
            setValue("contactList", userInfo.phone_numbers);
        }
    }, [editName, editUserName, editDescription, editNoticeEmail, editContactList]);

    useEffect(() => {
        try {
            if (authentication.info) {
                getUserInfo();
            }
        } catch (error) {
            window.location = "https://login.megazone.com";
        }
        return () => {
            UserActions.resetUserList();
        };
    }, [authentication, userId]);

    const getUserInfo = async () => {
        const userInfo = await UserActions.getUserInfo(userId);
        if (userInfo) {
            setUserInfo({
                user_email: userInfo.user_email || "-",
                user_name: userInfo.user_name || "-",
                admin_id: userInfo.admin_id || "-",
                reception_email: userInfo.reception_email || "-",
                billing_email: userInfo.billing_email || "-",
                phone_numbers: userInfo.phone_numbers || "-",
                created_at: userInfo.created_at || "-",
                updated_at: userInfo.updated_at || "-",
                creator_id: userInfo.creator_id || "-",
                creator_name: userInfo.creator_name || "-",
                updater_id: userInfo.updater_id || "-",
                updater_name: userInfo.updater_name || "-",
                name: userInfo.name || "-",
                description: userInfo.description || "",
                toggle: userInfo.reception_email === userInfo.user_email ? true : false,
            });
        }
    };

    const toCamelCase = useCallback((value) => value.split(/(?=[A-Z])/).join('_').toLowerCase(), []);

    const handleSet = useCallback((object) => {
        return false;
    }, []);

    const isObjectNotEmpty = useCallback((object) => {
        const objectToArray = Object.keys(object);
        return objectToArray.length !== 0;
    }, []);

    const handlePatch = useCallback(async (key, closeSetState) => {
        if(isObjectNotEmpty(errors)) return false;

        const valueTypes = (value) => {
            if(value === `phoneNumbers`) {
                return contactList.map((item, index) => {
                    item.idx = index;
                    return item;
                })
            } else if(value === `receptionEmail`) {
                return toggle ? getValues(key) : receptionEmail
            } else {
                return getValues(key)
            }
        }

        const queryParams = {
            [toCamelCase(key)]: valueTypes(key)
        };
        const response = key === `password`
            ? await UserActions.editPassword(userId, queryParams)
            : await UserActions.editUserInfo(userId, queryParams);

        if (response) {
            getUserInfo();
            closeSetState(false);
        }
    }, [errors, isObjectNotEmpty, getValues, toCamelCase, contactList, receptionEmail, toggle, getUserInfo]);

    useEffect(() => {
        if (!toggle) {
            setValue("receptionEmail", "");
        } else {
            setValue("receptionEmail", userInfo.user_email);
        }
    }, [toggle]);

    return (
        <React.Fragment>
            {user.pending ? <Loader /> : ""}
            <div className="contents">
                <div className="page-header">
                    <div className="page-header-info">
                        <div className="page-prev">
                            <a href="/accounts">
                                <i className="sprite sprite-back"></i>
                                <span className="hidden">prev</span>
                            </a>
                        </div>
                        <h2 className="page-title">
                            {userInfo.name}({userInfo.user_email})
                        </h2>
                    </div>
                    {/* <div className="page-header-tools">
                        <button type="button" className="btn btn-outline-default">
                            <span>Delete</span>
                        </button>
                    </div> */}
                </div>

                <form onSubmit={handleSubmit((object) => handleSet(object))}>
                    <div className={`card card-form`}>
                        <div className="card-header">
                            <strong>계정 정보</strong>
                        </div>
                        <div className="card-body">
                            <div className="row row-column-vertical-line">
                                <div className="col">
                                    <div className="list-overview">
                                        <ul>
                                            <li>
                                                <div className="title">
                                                    <strong>계정 이메일</strong>
                                                </div>
                                                <div className="content">
                                                    <span>{userInfo.user_email}</span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="title">
                                                    <strong>사용자</strong>
                                                    <button type="button"
                                                        className="btn btn-icon-solid btn-edit"
                                                        hidden={editUserName}
                                                        onClick={() => {
                                                            setEditUserName(true);
                                                        }}
                                                    >
                                                        <i className="sprite sprite-edit"></i>
                                                    </button>
                                                </div>
                                                <div className="content">
                                                    {editUserName ? (
                                                        <>
                                                            <div
                                                                className={`form-input ${
                                                                    errors.userName ? "form-error" : ""
                                                                }`}
                                                            >
                                                                <input
                                                                    type={"text"}
                                                                    id={"userName"}
                                                                    name={"userName"}
                                                                    placeholder={userInfo.user_name}
                                                                    ref={register({
                                                                        required: "사용자를 입력해주세요.",
                                                                        validate: {
                                                                            check1: (value) =>
                                                                                /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\*]+$/.test(
                                                                                    value,
                                                                                ) || "한글, 영문, 숫자만 가능합니다.",
                                                                            check2: (value) =>
                                                                                value.length < 30 ||
                                                                                "30자를 초과할 수 없습니다.",
                                                                        },
                                                                    })}
                                                                />
                                                                {errors.userName ? (
                                                                    <div className="form-message">
                                                                        <p>{errors.userName.message}</p>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className="btns-editable">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-icon btn-sm"
                                                                    onClick={() => {
                                                                        setEditUserName(false);
                                                                    }}
                                                                >
                                                                    <i className="sprite sprite-edit-cancel"></i>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-icon btn-sm btn-outline-primary"
                                                                    onClick={()=> handlePatch('userName', setEditUserName)}
                                                                >
                                                                    <i className="sprite sprite-edit-submit"></i>
                                                                </button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <span>{userInfo.user_name}</span>
                                                    )}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="title">
                                                    <strong>비밀번호</strong>
                                                    <button type="button"
                                                        className="btn btn-icon-solid btn-edit"
                                                        hidden={editPassword}
                                                        onClick={() => {
                                                            setEditPassword(true);
                                                        }}
                                                    >
                                                        <i className="sprite sprite-edit"></i>
                                                    </button>
                                                </div>
                                                <div className="content">
                                                    {editPassword ?
                                                        <>
                                                            <div className='box-border box-border-sm box-border-radius'>
                                                                <div className={`form-input ${errors.password ? "form-error" : ""}`}>
                                                                    <input
                                                                        type={"password"}
                                                                        id={"password"}
                                                                        name={"password"}
                                                                        placeholder="영문 대/소문자, 숫자, 특수문자 조합 8~15자"
                                                                        ref={register({
                                                                            required: "비밀번호를 입력해주세요.",
                                                                            pattern: {
                                                                                value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,15}$/i,
                                                                                message: "영문 대/소문자, 숫자, 특수문자 포함한 8~15자로 조합해주세요.",
                                                                            },
                                                                        })}
                                                                    />
                                                                    {errors.password ? (
                                                                        <div className="form-message">
                                                                            <p>{errors.password.message}</p>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                                <div className={`form-input ${errors.confirmPassword ? "form-error" : ""}`}>
                                                                    <input
                                                                        type={"password"}
                                                                        id={"confirmPassword"}
                                                                        name={"confirmPassword"}
                                                                        placeholder="비밀번호 재입력"
                                                                        ref={register({
                                                                            required: "비밀번호를 한번 더 입력해주세요.",
                                                                            validate: (value) => {
                                                                                return value === password || "비밀번호가 일치하지 않습니다.";
                                                                            },
                                                                        })}
                                                                    />
                                                                    {errors.confirmPassword ? (
                                                                        <div className="form-message">
                                                                            <p>{errors.confirmPassword.message}</p>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="btns-editable">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-icon btn-sm"
                                                                    onClick={() => {
                                                                        setEditPassword(false);
                                                                    }}
                                                                >
                                                                <i className="sprite sprite-edit-cancel"></i>
                                                                </button>
                                                                <button type="button" className="btn btn-icon btn-sm btn-outline-primary" onClick={() => handlePatch('password', setEditPassword)}>
                                                                    <i className="sprite sprite-edit-submit"></i>
                                                                </button>
                                                            </div>
                                                        </>
                                                        : "••••••••"}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="title">
                                                    <strong>연락처</strong>
                                                    <button type="button"
                                                        className="btn btn-icon-solid btn-edit"
                                                        hidden={editContactList}
                                                        onClick={() => {
                                                            setEditContactList(true);
                                                        }}
                                                    >
                                                        <i className="sprite sprite-edit"></i>
                                                    </button>
                                                </div>

                                                {editContactList ? (
                                                    <div className="content">
                                                        <div className='box-border box-border-sm box-border-radius'>
                                                            <ContactList
                                                                register={register}
                                                                errors={errors}
                                                                setValue={setValue}
                                                                isSubmit={isValidationOnChange}
                                                                contactList={userInfo.phone_numbers}
                                                            />
                                                        </div>
                                                        <div className="btns-editable">
                                                            <button
                                                                type="button"
                                                                className="btn btn-icon btn-sm"
                                                                onClick={() => {
                                                                    setEditContactList(false);
                                                                }}
                                                            >
                                                                <i className="sprite sprite-edit-cancel"></i>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-icon btn-sm btn-outline-primary"
                                                                onClick={() => handlePatch(`phoneNumbers`, setEditContactList)}
                                                            >
                                                                <i className="sprite sprite-edit-submit"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    userInfo.phone_numbers &&
                                                    userInfo.phone_numbers.map((item, i) => {
                                                        return (
                                                            <div className="content" key={i}>
                                                                <p>{item.phone_number}</p>
                                                            </div>
                                                        );
                                                    })
                                                )}
                                            </li>
                                            <li>
                                                <div className="title">
                                                    <strong>공지 이메일</strong>
                                                    <button type="button"
                                                        className="btn btn-icon-solid btn-edit"
                                                        hidden={editNoticeEmail}
                                                        onClick={() => {
                                                            setEditNoticeEmail(true);
                                                        }}
                                                    >
                                                        <i className="sprite sprite-edit"></i>
                                                    </button>
                                                </div>
                                                <div className="content">
                                                    {editNoticeEmail ? (
                                                        <>
                                                            <div className='box-border box-border-sm box-border-radius'>
                                                                <div className="row row-column align-items-center">
                                                                    <div className="col flex-grow-1">
                                                                        <div
                                                                            className={`form-input ${
                                                                                errors.receptionEmail && !toggle
                                                                                    ? "form-error"
                                                                                    : ""
                                                                            }`}
                                                                        >
                                                                            <input
                                                                                type={"text"}
                                                                                id={"receptionEmail"}
                                                                                name={"receptionEmail"}
                                                                                placeholder={userInfo.reception_email}
                                                                                disabled={toggle}
                                                                                ref={register({
                                                                                    pattern: {
                                                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                                                        message: "이메일 형식이 아닙니다.",
                                                                                    },
                                                                                })}
                                                                            />
                                                                            {errors.receptionEmail && !toggle ? (
                                                                                <div className="form-message">
                                                                                    <p>{errors.receptionEmail.message}</p>
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col col-4">
                                                                        <label htmlFor="toggle" className="form-checkbox">
                                                                            <input
                                                                                type={"checkbox"}
                                                                                id={"toggle"}
                                                                                name={"toggle"}
                                                                                defaultChecked={userInfo.toggle}
                                                                                ref={register}
                                                                            />
                                                                            <i></i>
                                                                            <span className="text-secondary">
                                                                                계정 이메일과 동일
                                                                            </span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="btns-editable">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-icon btn-sm"
                                                                    onClick={() => {
                                                                        setEditNoticeEmail(false);
                                                                    }}
                                                                >
                                                                    <i className="sprite sprite-edit-cancel"></i>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-icon btn-sm btn-outline-primary"
                                                                    onClick={() => handlePatch(`receptionEmail`, setEditNoticeEmail)}
                                                                >
                                                                    <i className="sprite sprite-edit-submit"></i>
                                                                </button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <span>{userInfo.reception_email}</span>
                                                    )}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="title">
                                                    <strong>이메일로 청구 받기</strong>
                                                </div>
                                                <div className="content">
                                                    <span>{userInfo.billing_email}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="list-overview">
                                        <ul>
                                            <li>
                                                <div className="title">
                                                    <strong>계정 이름</strong>
                                                    <button type="button"
                                                        className="btn btn-icon-solid btn-edit"
                                                        hidden={editName}
                                                        onClick={() => {
                                                            setEditName(true);
                                                        }}
                                                    >
                                                        <i className="sprite sprite-edit"></i>
                                                    </button>
                                                </div>
                                                <div className="content">
                                                    {editName ? (
                                                        <>
                                                            <div
                                                                className={`form-input ${errors.name ? "form-error" : ""}`}
                                                            >
                                                                <input
                                                                    type={"text"}
                                                                    id={"name"}
                                                                    name={"name"}
                                                                    placeholder={userInfo.name}
                                                                    ref={register({
                                                                        required: "계정 이름을 입력해주세요.",
                                                                        validate: {
                                                                            check1: (value) =>
                                                                                value.length < 100 ||
                                                                                "100자를 초과할 수 없습니다.",
                                                                        },
                                                                    })}
                                                                />
                                                                {errors.name ? (
                                                                    <div className="form-message">
                                                                        <p>{errors.name.message}</p>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className="btns-editable">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-icon btn-sm"
                                                                    onClick={() => {
                                                                        setEditName(false);
                                                                    }}
                                                                >
                                                                    <i className="sprite sprite-edit-cancel"></i>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-icon btn-sm btn-outline-primary"
                                                                    onClick={()=> handlePatch('name', setEditName)}
                                                                >
                                                                    <i className="sprite sprite-edit-submit"></i>
                                                                </button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <span>{userInfo.name}</span>
                                                    )}
                                                </div>
                                            </li>
                                            <li>
                                                <div className="title">
                                                    <strong>설명</strong>
                                                    <button type="button"
                                                        className="btn btn-icon-solid btn-edit"
                                                        hidden={editDescription}
                                                        onClick={() => {
                                                            setEditDescription(true);
                                                        }}
                                                    >
                                                        <i className="sprite sprite-edit"></i>
                                                    </button>
                                                </div>
                                                <div className="content">
                                                    {editDescription ? (
                                                        <>
                                                            <div
                                                                className={`form-textarea ${
                                                                    errors.description ? "form-error" : ""
                                                                }`}
                                                            >
                                                                <textarea
                                                                    type={"text"}
                                                                    id={"description"}
                                                                    name={"description"}
                                                                    placeholder={userInfo.description}
                                                                    ref={register({
                                                                        validate: {
                                                                            check1: (value) =>
                                                                                value.length < 1000 ||
                                                                                "1000자를 초과할 수 없습니다.",
                                                                        },
                                                                    })}
                                                                />
                                                                {errors.description ? (
                                                                    <div className="form-message">
                                                                        <p>{errors.description.message}</p>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className="btns-editable">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-icon btn-sm"
                                                                    onClick={() => {
                                                                        setEditDescription(false);
                                                                    }}
                                                                >
                                                                    <i className="sprite sprite-edit-cancel"></i>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-icon btn-sm btn-outline-primary"
                                                                    onClick={()=> handlePatch('description', setEditDescription)}
                                                                >
                                                                    <i className="sprite sprite-edit-submit"></i>
                                                                </button>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <span>{userInfo.description ? userInfo.description : "-"}</span>
                                                    )}
                                                </div>
                                            </li>
                                        </ul>
                                        <hr />
                                        <ul>
                                            <li>
                                                <div className="title">
                                                    <strong>생성자</strong>
                                                </div>
                                                <div className="content">
                                                    <span>
                                                        {userInfo.creator_name}
                                                        <span className="text-secondary">({userInfo.creator_id})</span>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="title">
                                                    <strong>생성일시</strong>
                                                </div>
                                                <div className="content">
                                                    <span>
                                                        {moment(userInfo.created_at).format("YYYY-MM-DD HH:mm:ss")}
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="title">
                                                    <strong>수정자</strong>
                                                </div>
                                                <div className="content">
                                                    <span>
                                                        {userInfo.updater_name}
                                                        <span className="text-secondary">({userInfo.updater_id})</span>
                                                    </span>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="title">
                                                    <strong>수정일시</strong>
                                                </div>
                                                <div className="content">
                                                    <span>
                                                        {moment(userInfo.updated_at).format("YYYY-MM-DD HH:mm:ss")}
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

        </React.Fragment>
    );
};

export default withRouter(AccountDetail);
