import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import Select from "react-select";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import * as usersActions from "reducers/modules/users";
import Loader from "components/Loader";

const searchFilterOptions = [
    { value: "Customer", label: "계정 이름" },
    { value: "ID", label: "계정 이메일" },
    { value: "Name", label: "사용자" },
];

const AccountList = (props) => {
    const { history } = props;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [searchFilterInputValue, setSearchFilterInputValue] = useState("");
    const [searchFilterValue, setSearchFilterValue] = useState(searchFilterOptions[0]);
    const [userList, setUserList] = useState([]);
    const [ownerSearchValue, setOwnerSearchValue] = useState("");
    const [isSearched, setIsSearched] = useState(false);

    const dispatch = useDispatch();
    const { users, authentication } = useSelector((store) => store);

    const createAccount = () => {
        history.push("/accounts/add");
    };
    const UsersActions = bindActionCreators(usersActions, dispatch);

    useEffect(async () => {
        if (authentication.info) {
            await UsersActions.getUserList();
        }
        return () => {
            UsersActions.resetUserList();
        };
    }, [authentication]);

    useEffect(() => {
        if (users.data) {
            if (isSearched) {
                handleSearch(users.data);
            } else {
                setUserList(users.data);
            }
        }
    }, [users]);

    const handleSearch = (usersData) => {
        const copyUsers = Object.assign([], usersData || users.data);
        const newList = copyUsers.filter((user) => {
            const searchOwner = user.creator_name.includes(ownerSearchValue || "") || user.creator_id.includes(ownerSearchValue || "");

            const getSearchFilter = (value, inputValue, user) => {
                switch (value) {
                    case "Name":
                        return user.user_name.includes(inputValue || "");
                    case "ID":
                        return user.user_email.includes(inputValue || "");
                    case "Customer":
                        return user.name.includes(inputValue || "");
                    default:
                        return false;
                }
            }

            const searchFilter = getSearchFilter(searchFilterValue.value, searchFilterInputValue, user);

            const searchDate =
                startDate && endDate
                    ? moment(user.created_at).isAfter(startDate.hours("0").minutes("0").seconds("0")) && moment(user.created_at).isBefore(endDate.hours("23").minutes("59").seconds("59"))
                    : startDate && !endDate
                    ? moment(user.created_at).isAfter(startDate.hours("0").minutes("0").seconds("0"))
                    : true;
            const isTrue = searchOwner && searchFilter && searchDate;
            return isTrue && user;
        });
        setIsSearched(true);
        setUserList(newList);
    };

    const handleReset = () => {
        setStartDate(null);
        setEndDate(null);
        setOwnerSearchValue("");
        setSearchFilterInputValue("");
        setSearchFilterValue(searchFilterOptions[0]);
        setIsSearched(false);
    };

    const refreshUserList = async () => {
        await UsersActions.getUserList();
    };

    const SyncUserList = async () => {
        await UsersActions.getUserListSync();
    };

    return (
        <div className="contents">
            {users.pending ? <Loader /> : ""}
            <div className="page-header">
                <div className="page-header-info">

                </div>
                <div className="page-header-tools">
                    <button type="button" className="btn btn-outline-primary" onClick={createAccount}>
                        <i className="sprite sprite-add-primary"></i>
                        <strong>계정 생성</strong>
                    </button>
                </div>
            </div>
            <div className="card card-search">
                <div className="card-body">
                    <form onSubmit={(e) => {
                            e.preventDefault();
                            handleSearch();
                        }
                    }>
                        <div className="form-search">
                            <div className="area-search">
                                <div className="row">
                                    <div className="col col-keyword flex-grow-1">
                                        <div className="search-form-group">
                                            <Select
                                                className={`select2-container`}
                                                classNamePrefix={"select2-selection"}
                                                defaultValue={searchFilterValue}
                                                options={searchFilterOptions}
                                                onChange={setSearchFilterValue}
                                            />
                                            <input
                                                type="text"
                                                onChange={(e) => setSearchFilterInputValue(e.target.value)}
                                                value={searchFilterInputValue}
                                            />
                                        </div>
                                    </div>
                                    <div className="col col-owner flex-grow-1">
                                        <div className="search-form-group">
                                            <div className="search-form-group-title">생성자</div>
                                            <input
                                                type="text"
                                                onChange={(e) => {
                                                    setOwnerSearchValue(e.target.value);
                                                }}
                                                value={ownerSearchValue}
                                            />
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="search-form-group">
                                            <div className="search-form-group-title">생성일시</div>
                                            <DateRangePicker
                                                startDate={startDate}
                                                startDateId={"startDate"}
                                                endDate={endDate}
                                                endDateId={"endDate"}
                                                onDatesChange={({ startDate, endDate }) => {
                                                    setStartDate(startDate);
                                                    setEndDate(endDate);
                                                }}
                                                onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                                                focusedInput={focusedInput}
                                                displayFormat={"YYYY-MM-DD"}
                                                showDefaultInputIcon={true}
                                                small={true}
                                                numberOfMonths={1}
                                                minimumNights={0}
                                                isOutsideRange={() => false}
                                                inputIconPosition="before"
                                                startDatePlaceholderText="시작일"
                                                endDatePlaceholderText="종료일"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="area-btns">
                                <div className="btn-group">
                                    <button type="submit" className="btn btn-dark" onClick={() => handleSearch()}>
                                        <i className="sprite sprite-search"></i>
                                        <span>검색</span>
                                    </button>
                                    <button type="button" className="btn btn-dark btn-icon" onClick={handleReset}>
                                        <i className="sprite sprite-reset-active"></i>
                                        <span className="hidden">reset</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="card ">
                <div className="card-body">
                    <div className="control">
                        <div className="control-status">
                            <strong>
                                총 <span className="text-primary">{userList.length}</span>
                            </strong>
                        </div>
                        <div className="control-tools">
                            <button type="button" className="btn btn-outline-default" onClick={SyncUserList}>
                                <i className="sprite sprite-sync"></i>
                                <span>동기화</span>
                            </button>
                            <button type="button" className="btn btn-outline-default" onClick={refreshUserList}>
                                <i className="sprite sprite-refresh"></i>
                                <span>새로고침</span>
                            </button>
                        </div>
                    </div>
                    <div className="table table-hover">
                        <table>
                            <colgroup>
                                <col width={"150px"} />
                                <col width={"150px"} />
                                <col width={"150px"} />
                                <col width={"150px"} />
                                <col width={"150px"} />
                                <col width={"150px"} />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>계정 이름</th>
                                    <th>계정 이메일</th>
                                    <th>사용자</th>
                                    <th>연락처</th>
                                    <th>생성자</th>
                                    <th>생성일시</th>
                                </tr>
                            </thead>
                            <tbody>
                                {userList.length > 0 ? (
                                    userList.map((item, index) => {
                                        return (
                                            <tr key={index} onClick={ () => history.push(`/accounts/${item.id}`)}>
                                                <td>
                                                    {item.name}
                                                </td>
                                                <td>
                                                    {item.user_email}
                                                </td>
                                                <td>{item.user_name}</td>
                                                <td>
                                                    {item.phone_numbers &&
                                                        item.phone_numbers.map((item, index) => {
                                                            return <p key={index}>{item.phone_number}</p>;
                                                        })}
                                                </td>
                                                <td>
                                                    <div className="list-content">
                                                        <div className="list-title">
                                                            <span>{item.creator_name}</span>
                                                            <small>{item.creator_id}</small>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}</td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan={6} className='text-center'>사용자 정보가 없습니다.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="control">
                        <div className="control-tools">
                            <button type="button" className="btn btn-outline-default" onClick={refreshUserList}>
                                <i className="sprite sprite-refresh"></i>
                                <span>새로고침</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(AccountList);
