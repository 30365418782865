import React from "react";
import { Route } from "react-router-dom";
const RouteProvider = (props) => {
    const { component: Component, ...rest } = props;
    const Layout = rest.layout ? rest.layout : <React.Fragment></React.Fragment>;

    return (
        <Route
            rest
            render={(renderProps) => {
                renderProps.match = props.computedMatch;
                return (
                    <Layout>
                        <Component {...renderProps} />
                    </Layout>
                );
            }}
        />
    );
};

export default RouteProvider;
