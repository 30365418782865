import React, { useEffect } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { routes } from "./routes";
import RouteProvider from "routes/RouteProvider";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as adminActions from "reducers/modules/authentication";
import * as toastActions from "reducers/modules/toastMessage";
import "./styles/style.scss";
import { axios } from "utils/axiosWrapper";

const App = ({ location }) => {
    const dispatch = useDispatch();
    const AdminActions = bindActionCreators(adminActions, dispatch);
    const ToastActions = bindActionCreators(toastActions, dispatch);

    const handleWindow = () => {
        let body = document.querySelector("body");
        if (window.innerWidth > body.clientWidth + 5) {
            body.classList.add("has-scrollbar");
            body.setAttribute("style", "--scroll-bar-width: " + (window.innerWidth - body.clientWidth) + "px");
        } else {
            body.classList.remove("has-scrollbar");
        }
    };

    useEffect(async () => {
        handleWindow();
        window.addEventListener("resize", handleWindow);
        const { tid } = await AdminActions.checkAdmin();

        if (!tid) {
            AdminActions.resetInfo();
            window.location = "https://login.megazone.com";
        } else {
            axios._interceptors.response.use(
                (response) => {
                    return response;
                },
                (error) => {
                    if (error.response) {
                        if (error.response.status === 403 || error.response.status === 401) {
                            return (window.location = "https://login.megazone.com");
                        }
                        const msg =
                            error.response.data.constructor === Object ? error.response.data.message : "Error occured.";
                        ToastActions.setToast({
                            type: "error",
                            contents: `[Error:${error.response.status}] => ${msg}`,
                        });
                    }
                    return Promise.reject(error);
                },
            );
        }
        return () => {
            window.removeEventListener("resize", handleWindow);
        };
    }, []);

    return (
        <Switch>
            {routes.map((route, i) => (
                <RouteProvider key={i} {...route} />
            ))}
            <Route exact={true} path={"*"}>
                <Redirect to="/accounts" />
            </Route>
        </Switch>
    );
};

export default withRouter(App);
