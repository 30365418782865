import React from "react";

const Loader = () => {
    return (
        <div className={"loader"}>
            <div className={"bar-loader"}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
};

export default Loader;
