import { handleActions, createAction } from "redux-actions";
import uniqid from "uniqid";

//action type
const SET_MESSAGE = "SET_MESSAGE";
const DELETE_MESSAGE = "DELETE_MESSAGE";
const REMOVE_ALL_MESSAGE = "REMOVE_ALL_MESSAGE";

//reducer
const initialState = {
    data: [],
};

export default handleActions(
    {
        [SET_MESSAGE]: (state, action) => {
            const data = state.data;
            data.push(action.payload);
            return {
                data,
            };
        },
        [DELETE_MESSAGE]: (state, action) => {
            return {
                data: state.data.filter((v) => v._id !== action.payload),
            };
        },
        [REMOVE_ALL_MESSAGE]: (state) => {
            return {
                ...state,
                data: [],
            };
        },
    },
    initialState,
);
export const setToast = ({ _id = uniqid(), type, contents }) => (dispatch) => {
    dispatch({
        type: SET_MESSAGE,
        payload: {
            _id,
            type,
            contents,
        },
    });
};

export const deleteToast = createAction(DELETE_MESSAGE);
export const removeAllToast = createAction(REMOVE_ALL_MESSAGE);
