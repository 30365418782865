import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import uniqid from "uniqid";

const WithSelect = (props) => {
    const {
        placeholder,
        options,
        isMulti,
        onChange,
        hidden,
        onFocus,
        onBlur,
        hideSelectedOptions,
        closeMenuOnSelect,
        isDisabled,
        autoBlur,
        name,
        className,
        noOptionsMessage
    } = props;
    let defaultValue = "";
    let value = "";

    if (isMulti) {
        defaultValue = props.defaultValue;
        value = props.defaultValue;
    } else if (options) {
        defaultValue = options.filter((v) => v.value === props.defaultValue);
        value = options.filter((v) => v.value === props.defaultValue);
    }
    let Components = makeAnimated();
    const _id = uniqid();

    return (
        <Select
            id={_id}
            name={name}
            className={`select2-container ${hidden ? `d-none` : ""} ${className ? className : ""}`}
            classNamePrefix={"select2-selection"}
            components={Components}
            defaultValue={defaultValue}
            value={value}
            onChange={onChange}
            onFocus={(e) => {
                onFocus && onFocus(e);
                e.target.closest(".select2-container").classList.add("select2-focus");
            }}
            onBlur={(e) => {
                onBlur && onBlur(e);
                e.target.closest(".select2-container").classList.remove("select2-focus");
            }}
            placeholder={placeholder ? placeholder : "Select..."}
            options={options}
            isMulti={isMulti}
            hideSelectedOptions={hideSelectedOptions ? hideSelectedOptions : false}
            closeMenuOnSelect={closeMenuOnSelect ? closeMenuOnSelect : true}
            isDisabled={isDisabled}
            autoBlur={autoBlur}
            noOptionsMessage={() => "No Options"}
            menuPlacement={"auto"}
        />
    );
};

export default WithSelect;
