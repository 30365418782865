import { handleActions, createAction } from "redux-actions";
import { axios } from "utils/axiosWrapper";

const apiEndpoint = "https://z8qv6i1rg6.execute-api.ap-northeast-2.amazonaws.com/dev/api";
//action type
const USER_PENDING = "GET_USER_PENDING";
const GET_USER_FAILURE = "GET_USER_FAILURE";
const GET_USER_SUCCESS = "GET_USER_SUCCESS";
const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";
const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
const RESET_USER = "RESET_USER";
const EDIT_PASSWORD_SUCCESS = "EDIT_PASSWORD_SUCCESS";
const EDIT_PASSWORD_FAILURE = "EDIT_PASSWORD_FAILURE";

//reducer
const initialState = {
    pending: false,
    error: false,
    data: null,
};

export default handleActions(
    {
        [USER_PENDING]: (state) => {
            return {
                ...state,
                pending: true,
                error: false,
            };
        },
        [GET_USER_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [GET_USER_SUCCESS]: (state, action) => {
            let { data } = action.payload;
            return {
                pending: false,
                error: false,
                data: data.accounts,
            };
        },
        [CREATE_USER_FAILURE]: (state) => {
            return {
                ...state,
                pending: false,
                error: true,
            };
        },
        [CREATE_USER_SUCCESS]: (state, action) => {
            let { data } = action.payload;
            return {
                pending: false,
                error: false,
                data: data.accounts,
            };
        },
        [EDIT_PASSWORD_FAILURE]: (state, action) => {
            return {
                pending: false,
                error: true,
                data: {
                    ...state.data,
                },
            };
        },
        [EDIT_PASSWORD_SUCCESS]: (state, action) => {
            return {
                pending: false,
                error: false,
                data: {
                    ...state.data,
                },
            };
        },
        [RESET_USER]: (state) => {
            return {
                pending: false,
                error: false,
                data: null,
            };
        },
    },
    initialState,
);

function getUserInfoApi(id) {
    return axios.get(`${apiEndpoint}/accounts/${id}`);
}

export const getUserInfo = (id) => (dispatch, getState) => {
    dispatch({ type: USER_PENDING });
    return new Promise((resolve, reject) => {
        getUserInfoApi(id)
            .then((response) => {
                dispatch({
                    type: GET_USER_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: GET_USER_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

function editUserInfoApi(id, params) {
    return axios.patch(`${apiEndpoint}/accounts/${id}`, params);
}

export const editUserInfo = (id, params) => (dispatch, getState) => {
    dispatch({ type: USER_PENDING });
    return new Promise((resolve, reject) => {
        editUserInfoApi(id, params)
            .then((response) => {
                dispatch({
                    type: GET_USER_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: GET_USER_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

function createUserInfoApi(params) {
    return axios.post(`${apiEndpoint}/accounts`, params);
}

export const createUserInfo = (params) => (dispatch, getState) => {
    dispatch({ type: USER_PENDING });
    return new Promise((resolve, reject) => {
        createUserInfoApi(params)
            .then((response) => {
                dispatch({
                    type: CREATE_USER_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: CREATE_USER_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

function editPasswordApi(id, params) {
    return axios.put(`${apiEndpoint}/accounts/${id}/password`, params);
}

export const editPassword = (id, params) => (dispatch, getState) => {
    dispatch({ type: USER_PENDING });
    return new Promise((resolve, reject) => {
        editPasswordApi(id, params)
            .then((response) => {
                dispatch({
                    type: EDIT_PASSWORD_SUCCESS,
                    payload: response,
                });
                resolve(response.data);
            })
            .catch((error) => {
                dispatch({
                    type: EDIT_PASSWORD_FAILURE,
                    payload: error,
                });
                reject(error);
            });
    });
};

export const resetUserList = createAction(RESET_USER);
